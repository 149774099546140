<template>
  <v-col style="text-align: left" class="px-0">
    <v-snackbar top v-model="successSnackbar" color="success">
      <v-row align="center">
        <v-icon class="mx-3">mdi-check</v-icon>
        <v-col>
          <span>Ваше повідомлення успішно надіслано</span>
        </v-col>
      </v-row>
    </v-snackbar>
    <div class="contactBackground">
      <v-col cols="12" class="px-0 py-0">
        <!-- <span class="blackSpan">Ім'я<span class="requireColor">*</span></span> -->
        <v-text-field
          dense
          outlined
          color="#085895"
          label="Ім'я"
          placeholder="Введіть ім’я"
          v-model="userMessage.name"
          :error-messages="nameError"
          @blur="$v.userMessage.name.$touch()"
      /></v-col>
      <v-row no-gutters align="start">
        <v-col cols="12" class="px-0 py-0">
          <!-- <span class="blackSpan"
            >Email<span class="requireColor">*</span></span
          > -->
          <v-text-field
            dense
            outlined
            color="#085895"
            label="Email"
            placeholder="Введіть ваш Email"
            onfocus="this.removeAttribute('readonly');"
            readonly
            v-model="userMessage.email"
            :error-messages="emailError"
            @blur="$v.userMessage.email.$touch()"
        /></v-col>
        <v-col cols="12" class="px-0 py-0">
          <!-- <span class="blackSpan"
            >Номер телефону<span class="requireColor">*</span></span
          > -->
          <v-text-field
            dense
            outlined
            color="#085895"
            label="Номер телефону"
            placeholder="Введіть ваш номер телефону"
            v-model="userMessage.phone_number"
            :error-messages="phoneNumberError"
            v-mask="'+38 (###) ####-###'"
            @blur="$v.userMessage.phone_number.$touch()"
        /></v-col>
      </v-row>
      <!-- <span class="blackSpan"
        >Повідомлення<span class="requireColor">*</span></span
      > -->
      <v-textarea
        dense
        outlined
        color="#085895"
        label="Повідомлення"
        placeholder="Опис"
        v-model="userMessage.message"
        :error-messages="messageError"
        @blur="$v.userMessage.message.$touch()"
      />
      <v-row no-gutters justify="center" class="mt-1">
        <admin-form-button style="width: 100%" @click="sendMessage"
          >Надіслати</admin-form-button
        >
      </v-row>
    </div>
  </v-col>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, email, minLength } from "vuelidate/lib/validators";
import contactService from "@/requests/admin/contactService";
import AdminFormButton from "@/components/UI/buttons/adminFormButton.vue";
export default {
  mixins: [validationMixin],
  components: {
    AdminFormButton,
  },
  data: () => ({
    userMessage: {},
    successSnackbar: false,
  }),
  validations: {
    userMessage: {
      name: {
        required,
      },
      email: {
        required,
        email,
      },
      phone_number: {
        required,
        minLength: minLength(18),
      },
      message: {
        required,
      },
    },
  },
  methods: {
    async sendMessage() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        let message = new FormData();
        message.append("name", this.userMessage.name);
        message.append("email", this.userMessage.email);
        message.append(
          "phone_number",
          this.userMessage.phone_number.replace(/\D+/g, "")
        );
        message.append("message", this.userMessage.message);
        let response = await contactService.sendMessage(message);
        if (response.status == "success") {
          this.successSnackbar = true;
          this.userMessage = {};
          this.$v.$reset();
        }
      }
    },
  },
  computed: {
    nameError() {
      const errors = [];
      if (!this.$v.userMessage.name.$dirty) {
        return errors;
      }
      !this.$v.userMessage.name.required && errors.push("Ім'я обов'язкове");
      return errors;
    },
    emailError() {
      const errors = [];
      if (!this.$v.userMessage.email.$dirty) {
        return errors;
      }
      !this.$v.userMessage.email.required && errors.push("Email обов'язковий");
      !this.$v.userMessage.email.email &&
        errors.push("Email введений некорректно");
      return errors;
    },
    phoneNumberError() {
      const errors = [];
      if (!this.$v.userMessage.phone_number.$dirty) {
        return errors;
      } else if (!this.$v.userMessage.phone_number.required) {
        errors.push("Номер телефону обов'язквовий");
        return errors;
      }
      if (!this.$v.userMessage.phone_number.minLength) {
        errors.push("Некорректно введено номер телефону");
      }
      return errors;
    },
    messageError() {
      const errors = [];
      if (!this.$v.userMessage.message.$dirty) {
        return errors;
      } else if (!this.$v.userMessage.message.required) {
        errors.push("Повідомлення обов'язкове");
        return errors;
      }
      return errors;
    },
  },
};
</script>

<style scoped>
@media only screen and (max-width: 1023px) {
  .contactBackground {
    border-radius: 10px;
    border-top: 10px solid var(--blue, #085895);
    background: #fff;
    box-shadow: 9px 12px 33px 0px rgba(46, 46, 46, 0.05),
      35px 50px 61px 0px rgba(46, 46, 46, 0.04),
      78px 112px 82px 0px rgba(46, 46, 46, 0.03),
      139px 198px 97px 0px rgba(46, 46, 46, 0.01),
      217px 310px 106px 0px rgba(46, 46, 46, 0);
    max-width: 480px;
    height: max-content;
    padding: 20px;
  }
}
@media only screen and (min-width: 1024px) {
  .contactBackground {
    border-radius: 10px;
    border-top: 10px solid var(--blue, #085895);
    background: #fff;
    box-shadow: 9px 12px 33px 0px rgba(46, 46, 46, 0.05),
      35px 50px 61px 0px rgba(46, 46, 46, 0.04),
      78px 112px 82px 0px rgba(46, 46, 46, 0.03),
      139px 198px 97px 0px rgba(46, 46, 46, 0.01),
      217px 310px 106px 0px rgba(46, 46, 46, 0);
    max-width: 480px;
    height: max-content;
    padding: 40px;
  }
}
</style>