<template>
  <div>
    <v-col class="px-0 py-0">
      <h3 class="contactTitle" style="text-align: left">Контакти</h3>
      <p class="contactText">Маєте якісь питання? Зв’яжіться з нами!</p>
      <v-col
        cols="px-0 py-0"
        :style="
          $vuetify.breakpoint.smAndDown
            ? 'margin-top: 32px'
            : 'margin-top: 56px'
        "
      >
        <v-row no-gutters align="start" style="margin-bottom: 32px">
          <v-col
            cols="12"
            xl="6"
            lg="6"
            md="6"
            sm="12"
            class="px-0 py-0"
            style="text-align: left"
            :style="$vuetify.breakpoint.smAndDown ? 'margin-bottom: 32px' : ''"
          >
            <v-row no-gutters align="start">
              <div class="iconBackground">
                <img
                  src="@/assets/img/contactIcons/supportIcon.svg"
                  width="32px"
                  height="32px"
                  alt="support"
                />
              </div>
              <v-col cols="8" style="text-align: left" class="ml-2">
                <span class="itemTitle">Підтримка 24/7</span><br />
                <p
                  class="itemDescription"
                  v-for="number in dispatchersPhoneNumber"
                  :key="number.id"
                  style="margin-top: 8px"
                >
                  <a
                    :href="`tel:+${number.value.replace(/\D/g, '')}`"
                    style="text-decoration: none; color: black"
                  >
                    {{ number.value }}
                  </a>
                </p>
              </v-col>
            </v-row>
          </v-col>
          <v-col
            cols="12"
            xl="6"
            lg="6"
            md="6"
            sm="12"
            class="px-0 py-0"
            style="text-align: left"
          >
            <v-row no-gutters align="start">
              <div class="iconBackground">
                <img
                  src="@/assets/img/contactIcons/phoneIcon.svg"
                  width="32px"
                  height="32px"
                  alt="phone"
                />
              </div>
              <v-col cols="8" style="text-align: left" class="ml-2">
                <span class="itemTitle">Для замовлення</span><br />
                <p
                  class="itemDescription"
                  v-for="number in phoneNumbersForOrder"
                  :key="number.id"
                  style="margin-top: 8px"
                >
                  <a
                    :href="`tel:+${number.value.replace(/\D/g, '')}`"
                    style="text-decoration: none; color: black"
                  >
                    {{ number.value }}
                  </a>
                </p>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row no-gutters align="center">
          <v-col
            cols="12"
            xl="6"
            lg="6"
            md="6"
            sm="12"
            class="px-0 py-0"
            style="text-align: left"
            :style="$vuetify.breakpoint.smAndDown ? 'margin-bottom: 32px' : ''"
          >
            <v-row no-gutters align="start">
              <div class="iconBackground">
                <img
                  src="@/assets/img/contactIcons/emailIcon.svg"
                  width="32px"
                  height="32px"
                  alt="map marker"
                />
              </div>
              <v-col cols="8" style="text-align: left" class="ml-2">
                <span class="itemTitle">Email</span><br />
                <span class="itemDescription"
                  ><a
                    :href="`mailto:${email.value}`"
                    style="text-decoration: none; color: black"
                  >
                    {{ email.value }}
                  </a></span
                >
              </v-col>
            </v-row>
          </v-col>
          <v-col
            cols="12"
            xl="6"
            lg="6"
            md="6"
            sm="12"
            class="px-0 py-0"
            style="text-align: left"
          >
            <v-row no-gutters align="start">
              <div class="iconBackground">
                <img
                  src="@/assets/img/contactIcons/mapMarkerIcon.svg"
                  width="32px"
                  height="32px"
                  alt="email"
                />
              </div>
              <v-col cols="8" style="text-align: left" class="ml-2">
                <span class="itemTitle">Місце розташування офісу</span><br />
                <span class="itemDescription">
                  {{ address.value }}
                </span>
              </v-col>
            </v-row></v-col
          >
        </v-row>
      </v-col>
    </v-col>
  </div>
</template>

<script>
import settingsService from "@/requests/admin/settingsService";
export default {
  data: () => ({
    dispatchersPhoneNumber: [],
    phoneNumbersForOrder: [],
    email: "",
    address: "",
  }),
  mounted() {
    this.getSettings();
  },
  methods: {
    async getSettings() {
      await settingsService.getSettingList().then((res) => {
        if (res.status == "Success") {
          this.dispatchersPhoneNumber = res.data.filter(
            (data) => data.type === "dispatchers"
          );
          this.email = res.data.filter(
            (data) => data.key == "support_email"
          )?.[0];
          this.address = res.data.filter((data) => data.key == "address")?.[0];
          this.phoneNumbersForOrder = res.data.filter(
            (data) => data.type == "contact"
          );
        }
      });
    },
  },
};
</script>

<style scoped>
.contactTitle {
  color: #1b1b1b;
  font-family: Roboto;
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 12px;
}
.contactText {
  color: #1b1b1b;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 1.6px;
  margin-bottom: 0px;
  text-align: left;
}
.itemTitle {
  color: #1b1b1b;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.itemTitleMobile {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.1em;
  color: #085895;
}
.itemDescription {
  color: #1b1b1b;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.iconBackground {
  border-radius: 5px;
  background: #085895;
  box-shadow: -1px 1px 3px 0px rgba(0, 0, 0, 0.1),
    -3px 4px 5px 0px rgba(0, 0, 0, 0.09), -8px 9px 7px 0px rgba(0, 0, 0, 0.05),
    -13px 17px 9px 0px rgba(0, 0, 0, 0.01), -21px 26px 9px 0px rgba(0, 0, 0, 0);
  width: 64px;
  height: 64px;
  display: grid;
  place-items: center;
  margin-right: 12px;
}
</style>